<template>
  <div class="new-con">
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $t('Payment') }}</h1>
        </div>
      </div>
    </section>

    <section
      style="background-color: white; padding-top: 36px; padding-bottom: 150px"
    >
      <div class="container">
        <iframe
          v-if="ids"
          :src="
            `https://php.kleopatramassage-beauty.com/web.php?type=package&id=${ids}&itemId=${id}&lang=${
              lang == 'lb' ? 'en' : lang
            }`
          "
          frameborder="0"
          style="width: 100%;height: 352px;"
        ></iframe>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import querystring from 'querystring';
export default {
  head: {
    title: {
      inner: 'Payment',
    },
    // Meta tags
    meta: [
      { name: 'application-name', content: 'Kleopatra Massage & Beauty' },
      {
        name: 'description',
        content: 'Kleopatra Massage & Beauty',
      }, // id to replace intead of create element
      // ...
      // Twitter
      { name: 'twitter:title', content: 'Kleopatra Massage & Beauty' },
      // with shorthand
      {
        n: 'twitter:description',
        c: 'Kleopatra Massage & Beauty',
      },
      // ...
      // Google+ / Schema.org
      { itemprop: 'name', content: 'Kleopatra Massage & Beauty' },
      { itemprop: 'description', content: 'Kleopatra Massage & Beauty' },
      // ...
      // Facebook / Open Graph
      // { property: 'fb:app_id', content: '123456789' },
      { property: 'og:title', content: 'Kleopatra Massage & Beauty' },
      // with shorthand
      {
        p: 'og:image',
        c: 'https://kleopatramassage-beauty.com/image/logo.png',
      },
      // ...
    ],
  },
  data() {
    return {
      lang: localStorage.lang,
      id: null,
      ids: null,
      order: null,

      amount: 0,
    };
  },
  methods: {
    httpg() {
      return axios.create({
        baseURL: `https://eu-prod.oppwa.com/v1/`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer OGFjOWE0Y2E4MzgzNTA5YjAxODM4M2ZjMmE2MDExY2Z8cDhHTWhuWXFiWA==`,
        },
      });
    },
    xc(value) {
      if (value) {
        return JSON.parse(value);
      }
      return [];
    },
  },
  created() {
    this.id = this.$route.params.id;

    this.amount = this.$route.params.amount;

    this.httpg()
      .post(
        'checkouts',
        querystring.stringify({
          entityId: '8ac9a4ca8383509b018383fdcc5811ec',
          amount: String(this.amount),
          currency: 'EUR',
          paymentType: 'DB',
        }),
      )
      .then((res2) => {
        this.ids = res2.data.id;
      });
  },
};
</script>
